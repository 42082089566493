import { React } from "react";
import ReactMarkdown from 'react-markdown'
import { motion } from 'framer-motion'
import { Link } from 'react-scroll'
import { useNavigate } from "react-router";

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import ed_1 from '../../assets/services/ed-project/ed_1.jpg'
import ed_2 from '../../assets/services/ed-project/ed_2.jpg'
import ed_3 from '../../assets/services/ed-project/ed_3.jpg'


const EdProject = () => {

  const navigate = useNavigate();

  const handleScrollTo = (elementId) => {
        navigate("/"); 
        setTimeout(() => {
          const element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      };

  const indiv_ed = [
    {
      id: 1,
      name: "ed_1",
      image: ed_1,
    },
    {
      id: 2,
      name: "ed_2",
      image: ed_2,
    },
    {
      id: 3,
      name: "ed_3",
      image: ed_3,
    },
    
  ]

  const markdownContent = `

Nurture the next generation of innovators and problem solvers with our tailored technical project creation services for school and college students at TalenTitan. We empower young minds to explore, learn, and create through hands-on technical projects. 

Our student-focused services encompass:  
  
1. **Project Ideation and Selection**

      - **Idea Generation Workshops:** Inspire students with creative ideation sessions to help them choose exciting project topics.

      - **Project Relevance:** We ensure that projects align with academic curricula and student interests.

2. **Mentorship and Guidance**

      - **Expert Mentors:** Our experienced mentors provide guidance, support, and technical expertise throughout the project journey.

      - **Progress Reviews:** Regular reviews and feedback sessions to keep students on track.

3. **Skill Development**

    - **Technical Training:** Offer students the opportunity to acquire and hone technical skills relevant to their projects.

    - **Coding and Programming:** Learn to code, program, and develop software and hardware solutions.

4. **Project Planning and Execution**

    - **Project Roadmap:** Create a structured project plan with defined goals and timelines.

    - **Hands-On Implementation:** Students build, test, and iterate on their projects, gaining practical experience.

5. **Resources and Tools**

    - **Access to Tools:** Provide students with access to necessary hardware, software, and development tools.

    - **Learning Materials:** Curated educational materials and resources to support project development.

6. **Presentation and Communication Skills**

    - **Presentation Training:** Help students develop effective communication and presentation skills.

    - **Demo Days:** Showcase student projects to peers, parents, and educators.

7. **Documentation and Reporting**

    - **Project Documentation:** Teach students to document their project processes, outcomes, and challenges.

    - **Final Reports:** Compile comprehensive final project reports for evaluation.

8. **Competition and Recognition**

    - **Participation in Competitions:** Encourage students to participate in technical competitions and hackathons.

    - **Recognition:** Highlight outstanding projects and celebrate student achievements.

9. **Collaboration and Teamwork**

    - **Team Building:** Foster collaboration and teamwork by encouraging group projects.

    - **Problem Solving:** Develop problem-solving skills through project challenges.

10. **Post-Project Guidance**

    - **Next Steps:** Help students plan their next steps, whether it's entering the workforce, pursuing higher education, or launching their own startups.

    - **Alumni Network:** Connect students with a community of alumni and professionals for ongoing support.

At TalenTitan, we believe in the power of hands-on learning and creativity. Our technical project creation services for school and college students provide a platform for students to explore their interests, develop practical skills, and prepare for future careers in technology and innovation.

Ready to inspire the next generation of tech leaders? Whether you are an educator or a student, contact us today to discuss how our technical project creation services can enrich your educational experiences.
  

`;
  return (
    <div>
      <Navbar/>
      <div className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center">
        <div className="p-4 pt-28">
          <p className="text-4xl font-bold inline text-[#97D9FF] underline text-center">Educational Project Creation</p>
        </div>
        <motion.div
          initial={{ x: -300 }} 
          animate={{ x: 0 }}   
          transition={{ duration: 1.5, ease: "easeOut" }}
          className="flex flex-wrap items-center justify-center w-full mx-auto">
            {indiv_ed.map(({ id, name, image }) => (
              <div key={id} className="p-2 mt-4">
  
                <img src={image} alt="" className="w-48 rounded-lg hover:saturate-0 hover:scale-105 duration-300 cursor-pointer" />

              </div>
        ))}

        </motion.div>
        <div className="markdown pt-10 px-32 md:px-40 ">
            <ReactMarkdown children = {markdownContent} sourcePos={false}/>
            <br/>
            <p className="text-2xl"><Link to="contact" smooth duration={500} spy className="cursor-pointer underline" onClick={() => handleScrollTo("contact")}>Contact</Link> us now!</p>
            <br/><br/><br/>
            </div>
      </div>
      <Footer/>
    </div>
  )
}

export default EdProject
