import React, { useEffect } from 'react';

const DeepLinkRedirect = () => {
  useEffect(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.talen_titan&pli=1';
      // const currentUrl = window.location.href;
      // const url = new URL(currentUrl);
      // const endpoint = url.pathname;
      // if(endpoint === "/delete_account") {
      //   window.location.replace('https//www.talentitan.com/delete_account');
      // } else {
      //   window.location.replace(`https//www.talentitan.com/battle/${endpoint.split("/")[2]}`);
      // }
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.talen_titan&pli=1';
    }
  }, []);

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default DeepLinkRedirect;