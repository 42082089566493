import { React } from "react";
import ReactMarkdown from 'react-markdown'
import { motion } from 'framer-motion'
import { Link } from 'react-scroll'
import { useNavigate } from "react-router";

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import product_1 from '../../assets/services/product-dev/product_1.jpg'
import product_2 from '../../assets/services/product-dev/product_2.jpg'
import product_3 from '../../assets/services/product-dev/product_3.jpg'

const ProductDev = () => {

  const navigate = useNavigate();

  const handleScrollTo = (elementId) => {
        navigate("/"); 
        setTimeout(() => {
          const element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      };

  const indiv_product = [
    {
      id: 1,
      name: "product_1",
      image: product_1,
    },
    {
      id: 2,
      name: "product_2",
      image: product_2,
    },
    {
      id: 3,
      name: "product_3",
      image: product_3,
    },
    
  ]

  const markdownContent = `
  Turn your innovative ideas into market-ready solutions with our comprehensive product development services at TalenTitan. Our seasoned team of engineers, designers, and strategists will guide you through every stage of product development, from concept to launch. Our end-to-end product development services encompass:
  
  1. **Conceptualization and Ideation**

      -  **Idea Validation:** We help you assess the feasibility and market potential of your product idea, ensuring it aligns with your business goals.

      - **Product Strategy:** Develop a clear roadmap and strategy that outlines the product's vision, features, and target audience.

  2. **Market Research and Competitive Analysis**

      - **Market Assessment:** Analyze market trends, customer needs, and competition to fine-tune your product concept.

      - **Competitive Benchmarking:** Gain insights from successful products in your industry to inform your product's design and positioning.

  3. Design and Prototyping

      - User-Centric Design: Craft intuitive and visually appealing user interfaces (UI) and user experiences (UX) to maximize user adoption and satisfaction.
      
      - Prototyping: Create interactive prototypes to visualize and test your product's functionality and design before development begins.

  4. Development and Testing

      - Full-Stack Development: Our expert developers turn design concepts into fully functional products, employing cutting-edge technologies and best practices.

      - Quality Assurance: Rigorous testing ensures your product is bug-free, reliable, and ready for launch.

  5. **Scaling and Deployment**

      - **Scalability:** Design your product architecture to easily accommodate growth and increased user demand.

      - **Deployment:** We oversee the deployment process, ensuring a smooth transition to production environments.

  6. Maintenance and Support

      - Ongoing Maintenance: Post-launch, our support team provides continuous updates, improvements, and technical support to keep your product running smoothly.

      - Monitoring and Optimization: Implement monitoring tools and conduct regular optimization to enhance product performance.

  7. Marketing and Launch Strategy

      - Go-to-Market Strategy: Develop a comprehensive marketing plan, including branding, digital marketing, and PR, to ensure a successful product launch.

      - Product Launch: Coordinate and execute a strategic product launch campaign to maximize visibility and user adoption.

  8. **Analytics and User Feedback**

      - **Data Analysis:** Utilize data analytics to gain insights into user behavior, product performance, and areas for improvement.

      - **User Feedback Integration:** Continuously gather and integrate user feedback to enhance the product's features and functionality.

  9. **Regulatory Compliance and Security**

      - **Compliance Assurance:** Ensure your product complies with relevant industry standards and regulations.

      - **Security Measures:** Implement robust security protocols to protect user data and maintain user trust.

  10. **Sustainability and Future Roadmap**

      - **Sustainability Initiatives:** Explore eco-friendly and sustainable product development practices.

      - **Future Enhancements:** Develop a roadmap for future product enhancements and updates to stay competitive.
  
  Unlock the potential of your product idea with TalenTitan. Whether you're launching a new digital platform, a mobile app, or a physical product, our product development services are tailored to meet your unique needs and ensure your success.

  Ready to bring your product to life? Contact us today to discuss how our product development services can turn your vision into a reality.
  `;
  return (
    

    <div>
      <Navbar/>
      <div className=" w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center">
        <div className="p-4 pt-28">
          <p className="text-4xl font-bold inline text-[#97D9FF] underline">Product Development</p>
        </div>
        <motion.div
          initial={{ x: -300 }} 
          animate={{ x: 0 }}   
          transition={{ duration: 1.5, ease: "easeOut" }}
          className="flex flex-wrap items-center justify-center w-full mx-auto">
            {indiv_product.map(({ id, name, image }) => (
              <div key={id} className="p-2 mt-4">
  
                <img src={image} alt="" className="w-48 rounded-lg hover:saturate-0 hover:scale-105 duration-300 cursor-pointer" />

              </div>
        ))}

        </motion.div>
        <div className="markdown pt-10 px-32 md:px-40 ">
            <ReactMarkdown children = {markdownContent} sourcePos={false}/>
            <br/>
            <p className="text-2xl"><Link to="contact" smooth duration={500} spy className="cursor-pointer underline" onClick={() => handleScrollTo("contact")}>Contact</Link> us now!</p>
            <br/><br/><br/>
            </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ProductDev