import { React } from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"


import services_bg from "../assets/services/services_bg.svg";
import services_1_productDevelopmenmt from "../assets/services/services_1_productDevelopment.jpg";
import services_2_projectManagement from "../assets/services/services_2_projectManagement.jpg";
import services_3_edProjectCreation from "../assets/services/services_3_edProjectCreation.jpg";
import services_4_iotProjectCreation from "../assets/services/services_4_iotProjectCreation.jpg";

const Services = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const indiv_services = [
    {
      id: 1,
      name: "Product Development",
      image: services_1_productDevelopmenmt,
      desc: "Turn your innovative ideas into market-ready solutions with our comprehensive product development services at TalenTitan.",
      link: "/product-development",
    },
    {
      id: 2,
      name: "Project Management",
      image: services_2_projectManagement,
      desc: "At TalenTitan, we understand that successful project management is the cornerstone of any thriving business. Our dedicated team of project management professionals is here to streamline your projects.",
      link: "/project-management",
    },
    {
      id: 3,
      name: "Educational Project Creation",
      image: services_3_edProjectCreation,
      desc: "Nurture the next generation of innovators and problem solvers with our tailored technical project creation services for school and college students at TalenTitan.",
      link: "/educational-project-creation",
    },
    {
      id: 4,
      name: "IoT Project Creation",
      image: services_4_iotProjectCreation,
      desc: "Transform your ideas into smart, connected solutions with our expert IoT project creation services.",
      link: "/iot-project-creation",
    },
  ];

  return (
    <motion.div
      initial={{
          opacity:0,
      }}
      whileInView={{
          opacity:1,
      }}
      transition={{
          duration:0.5,
      }}
      viewport={{
        once: false
    }}
      id="services"
      className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center"
    >
      <div
        className="h-full w-full absolute bg-cover bg-center"
        style={{
          backgroundImage: `url(${services_bg})`,
          backgroundSize: "cover",
        }}
      />

      <div className="p-4 pt-28 text-center">
        <p className="text-xl md:text-4xl font-bold inline text-[#97D9FF]">
          What other things we do?
        </p>
        <p className="text-sm md:text-md pt-5 px-10 text-[#97D9FF]">
          We also provide the following services for startups, students and
          everyone at very efficient costs. Click on them to know more!
        </p>
      </div>

      <motion.div 
      initial={{ x: -300 }}
      animate={{ x: 0 }} 
      transition={{ duration: 1, ease: "easeOut" }}
      viewport={false}
      className="flex flex-wrap items-center justify-center w-full mx-auto">
        {indiv_services.map(({ id, name, image, desc, link }) => (
          <div key={id} className="p-2">
            <Link
              to={link} onClick={scrollToTop}>
              <div className="relative">
                <img
                  src={image}
                  alt=""
                  className="w-60 md:w-72 rounded-lg hover:saturate-0 hover:scale-105 duration-300 cursor-pointer"
                />
                <div class="absolute bottom-0 left-0 p-2 text-white bg-gray-700 text-center pb-2 rounded-md">
                  <h2 class="text-xl font-semibold">{name}</h2>
                  <p class="text-xs">{desc}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Services;
