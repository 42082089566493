import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import Loader from "../models/loader";
import { get_auth_dispatcher } from '../models/api_dispatcher';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivPolicyPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#151719");
    const [size, setSize] = useState(250);
    const [data, setData] = useState('');

    useEffect(() => {
        get_auth_dispatcher("/api/v1.0/privacy_policy", setLoading, setColor, setSize)
            .then((data) => {
                setData(data.data);
                setLoading(false); 
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    return (
        <div>
            <Navbar />
            {loading ? (
                <div className="w-screen h-screen flex justify-center items-center bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center">
                    <Loader color={color} size={size} />
                </div>
            ) : (
                <div className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center">
                    <div className="p-4 pt-28">
                        <p className="text-4xl font-bold inline text-[#97D9FF] underline text-center">Privacy Policy</p>
                    </div>
                    <div className="markdown-2 pt-10 px-12 md:px-40">
                        <ReactMarkdown>{data}</ReactMarkdown>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
}

export default PrivPolicyPage;
