import React from 'react';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  position:fixed;
  top: 50%;
  left: 50%;
  border: 3px solid white;
  background-color: #151719;
  margin: 0 auto;
  width:3em;
  height:3em;
  margin-top: -1.5em; /*set to a negative number 1/2 of your height*/
  margin-left: -1.5em; /*set to a negative number 1/2 of your width*/
  z-index: 1;
`;

function Loader(props) {
  return (
    <ClipLoader
      color={'#ffffff'}
      loading={props.loading}
      speedMultiplier={1}
    />
  );
}

export default Loader;