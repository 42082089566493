import React from 'react'
import { motion } from "framer-motion"
import { useForm, SubmitHandler } from "react-hook-form"

const Inputs = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

const Contact = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (formData) => {
  window.location.href = `mailto:support@talentitan.com?subject=${formData.subject}&body=Hi, my name is ${formData.name}. ${formData.message} (${formData.email})`;
};


  return (
    <motion.div
      initial={{
          opacity:0,
      }}
      whileInView={{
          opacity:1,
      }}
      transition={{
          duration:0.5,
      }}
      id="contact"
      className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center"
    >
      <div className="p-4 pt-28">
        <p className="text-xl md:text-4xl font-bold inline text-[#97D9FF]">Let's Connect!</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}  className='flex flex-col space-y-2 mx-auto md:w-96 pb-20'>
        <input {...register('name')} placeholder='Name' className='outline-none bg-[#97D9FF] rounded-lg border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-[#be95c4]/40 hover:border-[#be95c4]/40' type="text" />
        <input {...register('email')}  placeholder='Email' className='outline-none bg-[#97D9FF] rounded-lg border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-[#be95c4]/40 hover:border-[#be95c4]/40' type="email" />


        <input {...register('subject')} placeholder='Subject' className='outline-none bg-[#97D9FF] rounded-lg border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-[#be95c4]/40 hover:border-[#be95c4]/40' type="text" />

        <textarea {...register('message')}  placeholder='Message' className='outline-none bg-[#97D9FF] rounded-lg border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-[#be95c4]/40 hover:border-[#be95c4]/40'/>

        <div className='flex items-center justify-center'>
        <button type='submit' className='bg-yellow-500 hover:bg-yellow-500/90 py-2 px-10 rounded-md text-white font-bold text-lg w-48'>Submit</button>
        </div>
      </form>
    </motion.div>
  )
}

export default Contact