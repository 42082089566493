import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
    <h1 className='text-3xl font-bold text-center p-10'>404 - Page Not Found!</h1>
    <div className='text-center underline'>
        <Link to="/">Go Home</Link>
    </div>
  </div>
);

export default NotFound;