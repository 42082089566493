import React from 'react'

import Navbar from './components/Navbar'
import Home from './components/Home'
import Battles from './components/Battles'
import Quizzes from './components/Quizzes'
import Feedback from './components/Feedback'
import Services from './components/Services'
import Contact from './components/Contact'
import Footer from './components/Footer'


function App(){
  return (
    <div>
      <Navbar/>
      <Home/>
      <Battles/>
      <Quizzes/>
      <Feedback/>
      <Services/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default App