import React, { useState } from 'react';
import Modal from 'react-modal';

import Loader from "../models/loader";
import { post_auth_dispatcher } from '../models/api_dispatcher';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const DeleteAccount = (props) => {
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState("#151719");
    const [size, setSize] = useState(250);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        // Call API to log in
        await post_auth_dispatcher("/api/v1.0/delete_user", {
            phone_number: phoneNumber,
            password: password
        }, setLoading, setColor, setSize).then(async (response) => {
            alert("Account deleted successfully")
        }).catch(async (err) => {
            console.log("Account can not be deleted")  
        });
    };

    return (
        <div>
            <Navbar />
            {loading ? (
                <div className="w-screen h-screen flex justify-center items-center bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center">
                    <Loader color={color} size={size} />
                </div>
            ) : (
                <div className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center">
                    <div className="p-4 pt-28">
                        <p className="text-4xl font-bold inline text-[#97D9FF] underline text-center">Delete your TalenTitan Account Permanently</p>
                    </div>
                    <div className="markdown-2 pt-10 px-12 md:px-40">
                        <p>Are you sure you want to delete your TalenTitan Account permanently as this action can't be undone</p>
                    <form onSubmit={handleFormSubmit} className="flex flex-col items-center">
                        Phone Number
                            <input 
                                type="number"
                                placeholder="Phone Number" 
                                value={phoneNumber} 
                                onChange={(e) => setPhoneNumber(e.target.value)} 
                                className="mb-4 p-2 border border-gray-400 rounded text-black"
                                required={true}
                            />
                             Password
                            <input 
                                type="password" 
                                placeholder="Password" 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                className="mb-4 p-2 border border-gray-400 rounded text-black"
                                required={true}
                            />
                            <button type="submit" className="bg-red-500 text-white py-2 px-4 rounded hover:bg-blue-600">Delete Account</button>
                        </form>
                        <br/>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
}

export default DeleteAccount;
