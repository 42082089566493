import axios from "axios"

import keys from "../config/keys"

const return_response = async (response) => {
    if (response.data.status_code === 403) {
        window.location.href = "/"
    }
    else if(response.data.status_code === 200 || response.data.status_code === 201 || response.data.status_code === 409){
        return(response.data)
    }
    else{
        alert(response.data.message);
        return(response.data)
    }
}

const get_auth_dispatcher = async (endpoint, setLoading, setColor, setSize) =>{
    return new Promise(((resolve, reject) => {
        setLoading(true)
        axios.get(`${keys.api_url}${endpoint}`, {
            headers: {
                'x-api-key': keys["x-api-key"]
            }
        }).then(async response => {
            setLoading(false)
            resolve(await return_response(response))
        }).catch(async response => {
            setLoading(false)
            resolve(await return_response(response))
        }).catch(async response => {
            setLoading(false)
            reject(await return_response(response.response))
        })
    }))
}

const post_auth_dispatcher = async (endpoint, body, setLoading, setColor, setSize) =>{
    return new Promise(((resolve, reject) => {
        setLoading(true)
        axios.post(`${keys.api_url}${endpoint}`, body, {
            headers: {
                'x-api-key': keys["x-api-key"]
            }
        }).then(async response => {
            setLoading(false)
            resolve(await return_response(response))
        }).catch(async response => {
            setLoading(false)
            reject(await return_response(response.response))
        })
    }))
}

export {
    get_auth_dispatcher,
    post_auth_dispatcher
}