import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import home_bg from '../assets/home/home_bg.svg';
import home_bg_2 from '../assets/home/home_bg_2.jpg';
import phone_left from '../assets/home/phone_left.png';
import phone_centre from '../assets/home/phone_centre.png';
import phone_right from '../assets/home/phone_right.png';
import { Link } from 'react-scroll';

const Home = () => {
  const [showContent, setShowContent] = useState(false);
  const isSmallScreen = window.innerWidth <= 768; 
  const [backgroundImage, setBackgroundImage] = useState(isSmallScreen ? home_bg : home_bg_2);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
      setBackgroundImage(home_bg);
    }, 2000);
  }, []);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
      }}
      id="home"
      className="top-20 md:top-0 h-screen md:h-screen w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center pb-8 z-10"
    >
      <div
        className="h-full w-full absolute bg-cover bg-center z-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
        }}
      />
      <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-start h-full px-4 md:flex-row z-20'>
        <motion.div
          initial={{ x: -300 }}
          animate={{ x: 0 }}
          transition={{ duration: 4, ease: "easeOut" }}
          className='flex flex-col justify-center h-full'>
          {showContent && (
            <>
              <h2 className='text-4xl sm:text-7xl font-bold text-white'>
                Be the {}
                <span className='bg-gradient-to-b from-[#97D9FF] to-[#8917C7] bg-clip-text text-transparent'>
                  TITAN
                </span>
              </h2>
              <h2 className='text-4xl sm:text-7xl font-bold text-white'>
                of your {}
                <span className='bg-gradient-to-b from-[#97D9FF] to-[#8917C7] bg-clip-text text-transparent'>
                  TALENT
                </span>
              </h2>
              <p className='text-white py-4 max-w-md text-lg'>
                TalenTitan welcomes you to the field of battle where you have a chance to show your talent to the entire world where you can be the talent of your own titan. It gives you a platform to set out your various natural aptitudes.
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.talen_titan&pli=1' target='_blank' rel="noreferrer">
                <button className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-xl bg-yellow-500 hover:bg-yellow-500/90 cursor-pointer'>
                  Download Our App
                </button>
              </a>
            </>
          )}
        </motion.div>

        <motion.div
          initial={{ x: 300 }}
          animate={{ x: 0 }}
          transition={{ duration: 4, ease: "easeOut" }}
          className='flex flex-row justify-between h-full'>
          {showContent && (
            <div className='flex justify-center items-center'>
              <img src={phone_left} alt='' className='h-52 md:h-96' />
              <img src={phone_centre} alt='' className='h-52 md:h-96' />
              <img src={phone_right} alt='' className='h-52 md:h-96' />
            </div>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Home;
