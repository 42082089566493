import React, { useRef, useEffect, useState } from 'react';
import { motion } from "framer-motion"

import quizzes_bg from "../assets/quizzes/quizzes_bg.svg";
import quiz_1_kdrama from "../assets/quizzes/quiz_1_kdrama.svg";
import quiz_2_webseries from "../assets/quizzes/quiz_2_webseries.svg";
import quiz_3_music from "../assets/quizzes/quiz_3_music.svg";
import quiz_4_sports from "../assets/quizzes/quiz_4_sports.svg";
import quiz_5_anime from "../assets/quizzes/quiz_5_anime.svg";
import quiz_6_technical from "../assets/quizzes/quiz_6_technical.svg";

const Quizzes = () => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  
  const maxWords = 50; // Define the maximum number of words for the description
  const [showModal, setShowModal] = useState(false); // State variable to control modal visibility
  const [selectedQuiz, setSelectedQuiz] = useState(null); // State variable to track the selected battle

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once it's in view
        }
      });
    });

    observer.observe(componentRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);


  const indiv_quizzes = [
    {
      id: 1,
      name: "K Drama Quiz",
      image: quiz_1_kdrama,
      desc: "K-drama stories are light, funny, dramatic—it's an emotional roller coaster ride. It lets you have feelings! The plot twists are not the typical and predictable storylines, but really, no matter what stories, it's how they deliver those 'kilig' scenes that get you, and the next thing you know, you're looking for more! So, here comes the Korean Datama Quiz which will test your knowledge of Korean dramas. Let’s see how much you know about Korean dramas, so come and play now on TalenTitan Application.",
    },
    {
      id: 2,
      name: "Webseries Quiz",
      image: quiz_2_webseries,
      desc: "Quizzes add an element of fun and entertainment to the web series experience. They allow viewers to engage with their favorite shows in a new and interactive way. Users can test their knowledge of the web series, characters, plotlines, and trivia. It can be satisfying to see how well you remember the details and intricacies of the show. Quizzes can serve as a learning opportunity. Users might discover new facts or details about the series that they missed while watching. It can deepen users' understanding and appreciation of the show. Download the TalenTitan Application now and start playing quizzes on Mirzapur, Stranger Things, Squid Game, Harry Porter, Friends, Money Heist, and many more.",
    },
    {
      id: 3,
      name: "Music Quiz",
      image: quiz_3_music,
      desc: "Music quizzes are a source of entertainment. Users enjoy testing their knowledge and challenging themselves with questions related to their favorite songs, artists, and genres. Music quizzes often introduce users to new songs, artists, or genres they may not have encountered before. This discovery can lead to a deeper appreciation for music and the exploration of new musical interests. Play K-POP and many other quizzes on the TalenTitan Platform.",
    },
    {
      id: 4,
      name: "Sports Quiz",
      image: quiz_4_sports,
      desc: "Sports quizzes allow users to test their knowledge of their favorite sports, teams, players, and events. This can be both entertaining and satisfying, as users see how well they know the world of sports. Quizzes can educate users about the history, rules, and trivia of various sports. Users may learn interesting facts about athletes, tournaments, and sporting milestones. Users often enjoy the competitive aspect of sports quizzes. They can compete with friends, family members, or online communities to see who has the most knowledge or can answer questions the fastest. Join the TalenTitan platform now to enjoy various sports quizzes including IPL Quiz, International Cricket Quiz, Olympics Quiz, Kabaddi Quiz, International Football Quiz, and many more.",
    },
    {
      id: 5,
      name: "Anime Quiz",
      image: quiz_5_anime,
      desc: "Anime quizzes allow users to test their knowledge of anime series, characters, and storylines. Fans can see how well they know their favorite shows and challenge themselves to remember details. For fans who have watched anime for years, quizzes can evoke feelings of nostalgia as they reminisce about their favorite anime series from the past. Anime quizzes often introduce users to new anime series, characters, and genres. Users may discover hidden gems and expand their anime horizons through these quizzes. So, download the TalenTitan Application now and enjoy anime quizzes like Dragon Ball Z, Naruto Quiz, One Piece, and many more.",
    },
    {
      id: 6,
      name: "Technical Quiz",
      image: quiz_6_technical,
      desc: "Technical quizzes hold significance for users, particularly those interested in or pursuing careers in the fields of technology, and engineering, as well as for those looking to expand their technical knowledge. Technical quizzes allow users to assess their understanding of specific technical topics, concepts, or skills. They serve as a self-evaluation tool to gauge one's proficiency in a particular area. Users can use technical quizzes as a means to reinforce and develop their technical skills. Regular practice through quizzes can help users master complex subjects and improve problem-solving abilities. Download the TalenTitan Application now to start playing technical quizzes like Java Quiz, Data Structures and Algorithm Quiz, Python Quiz, Javascript Quiz, C/C++ Quiz, AI & ML Quiz, HTML and CSS Quiz, and many more.",
    },
  ];

  return (
    <motion.div
      initial={{
          opacity:0,
      }}
      whileInView={{ 
          opacity:1,
      }}
      transition={{
          duration:0.5,
      }}
      id="quizzes"
      className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center pb-8"
    >
      <div
        className="h-full w-full absolute bg-cover bg-center"
        style={{
          backgroundImage: `url(${quizzes_bg})`,
          backgroundSize: "cover",
        }}
      />

      <div className="p-4 pt-28 text-center">
        <p className="text-xl md:text-4xl font-bold inline text-[#97D9FF]">Quizzes</p>
        <p className="text-sm md:text-md pt-5 px-10 text-[#97D9FF]">
          Click on the descriptions to know more!
        </p>
      </div>
      
      
      <motion.div
        ref={componentRef}
        initial={{ x: -300 }} 
        animate={{ x: 0 }}   
        transition={{ duration: 1, ease: "easeOut" }}
        className="flex flex-wrap items-center justify-center w-full mx-auto">
           {indiv_quizzes.map(({ id, name, image, desc }) => (
          <div key={id} className="p-2">
            <div className="relative">
              <img src={image} alt="" className="w-64 md:w-96 rounded-lg hover:saturate-0 hover:scale-105 duration-300 cursor-pointer" />
              <div className="absolute bottom-0 left-0 p-2 text-white text-center pb-2">
                <h2 className="text-xl font-semibold">{name}</h2>
                {desc.split(' ').length > maxWords ? (
                  <p className="text-xs cursor-pointer" onClick={() => { toggleModal(); setSelectedQuiz({ id, name, desc }); }}>
                    {showModal && selectedQuiz && selectedQuiz.id === id ? selectedQuiz.desc : `${desc.split(' ').slice(0, maxWords).join(' ')}...`}
                  </p>
                ) : (
                  <p className="text-xs">{desc}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </motion.div>

      {/* Modal */}
      {showModal && selectedQuiz && (
        <div className=" fixed inset-0 md:inset-40 flex items-center justify-center z-50">
          <div className="text-[#97D9FF] bg-gray-700 p-4 rounded-lg shadow-md">
            <p className="text-xl font-semibold">{selectedQuiz.name}</p>
            <p>{selectedQuiz.desc}</p>
            <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600" onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Quizzes;
