import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import AboutPage from './pages/AboutPage'
import PrivPolicyPage from './pages/PrivPolicyPage'
import TnCPage from './pages/TnCPage'
import NotFound from './pages/NotFound'

import EdProject from './pages/services/EdProject'
import IoTProject from './pages/services/IoTProject'
import ProductDev from './pages/services/ProductDev'
import ProjectMgmt from './pages/services/ProjectMgmt'
import RefundPolicyPage from './pages/RefundPolicyPage';
import DeepLinkRedirect from './pages/DeepLinkRedirect';
import DeleteAccount from './pages/DeleteAccount';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "about_us",
    element: <AboutPage />,
  },
  {
    path: "privacy_policy",
    element: <PrivPolicyPage />,
  },
  {
    path: "terms_conditions",
    element: <TnCPage />,
  },
  {
    path: "product-development",
    element: <ProductDev />,
  },
  {
    path: "project-management",
    element: <ProjectMgmt />,
  },
  {
    path: "educational-project-creation",
    element: <EdProject />,
  },
  {
    path: "iot-project-creation",
    element: <IoTProject />,
  },
  {
    path: "refund_policy",
    element: <RefundPolicyPage />,
  },
  {
    path: "battle/:id",
    element: <DeepLinkRedirect />,
  },
  {
    path: "delete_account",
    element: <DeleteAccount />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
