import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion"


import feedback_bg from '../assets/feedback/feedback_bg.svg'
import feedback_male from '../assets/feedback/feedback_male.jpg'
import feedback_female from '../assets/feedback/feedback_female.jpg'

const Feedback = () => {

  const customArrowLeft = (
    <button
      className="custom-carousel-arrow custom-carousel-arrow-left"
      aria-label="Previous"
    >
      &lt;
    </button>
  );

  const customArrowRight = (
    <button
      className="custom-carousel-arrow custom-carousel-arrow-right"
      aria-label="Next"
    >
      &gt;
    </button>
  );

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const indiv_feedback = [
    {
      id:1,
      name:'Karan',
      image:feedback_male,
      desc: "— I enjoyed playing battles with random people. It was thrilling to see how votes are increasing every second and I have to bring more friends to vote for my picture. I would suggest you all download this application and start playing the battles.",
    },
    {
      id:2,
      name:'Aman',
      image:feedback_male,
      desc:"— I mainly downloaded this application for playing quiz. I really enjoyed to answer so many questions for my favourite series (Money Heist). I was really surprised to see this kind of app which also do DP Battle and we can vote to anyone we want.",
    },
    {
      id:3,
      name:'Kritika',
      image:feedback_female,
      desc:"— I would suggest to also include quiz on competative exams. Students can play and also can test themself by playing quizzes. Students will also study harder to get more coins and get better leagues. Everyone wants to be in the higher league.",
    },
    {
      id:4,
      name:'Tapash',
      image:feedback_male,
      desc:"— The battles as well as quizzes are really engaging. The user interface of the app is also very amazing.",
    },
    {
      id:5,
      name:'Utpal',
      image:feedback_male,
      desc:"— I love how they are expanding their services to various domains. After being an user of the games, I'm sure the other services too will be top notch.",
    },
  ]

  return (
    <motion.div
      initial={{
          opacity:0,
      }}
      whileInView={{
          opacity:1,
      }}
      transition={{
          duration:0.5,
      }}
      id="feedback"
      className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center"
    >
      <div className="h-full w-full absolute bg-cover bg-center" style={{backgroundImage: `url(${feedback_bg})`, backgroundSize:'cover'}}/>
      <div className="p-4 pt-28">
        <p className="text-xl md:text-4xl font-bold inline text-[#97D9FF] text-center">See what our users say about us!</p>
      </div>

      <motion.div 
      initial={{ x: -300 }}
      animate={{ x: 0 }} 
      transition={{ duration: 1, ease: "easeOut" }}
      className='w-full md:w-1/2 lg:w-2/3 xl:w-4/5 mx-auto pb-20 '>
      {indiv_feedback.length !==0 && (
        <Carousel 
        responsive={responsive}
        customLeftArrow={customArrowLeft}
        customRightArrow={customArrowRight}
        
        >
          {indiv_feedback.map(({ id, image, name, desc }) => (
            <div key={id} className="flex-col">
              <img src={image} alt="" className="px-5 py-5 object-contain h-96 w-96 hover:saturate-0 duration-300 cursor-pointer rounded-lg"/>
              <div className="text-center text-2xl text-white">
                <h2>{name}</h2>
              </div>
              <div className="text-center text-sm md:text-md text-[#97D9FF] py-3 px-8">
                <p>{desc}</p>
              </div>
            </div>
          ))}
        </Carousel>)}
      </motion.div>

      {/* {indiv_feedback.length !== 0 && (
          <Carousel responsive={responsive}>
            {indiv_feedback.map(({ id, name, image, desc }) => (
              <div key={id} className="flex-col relative overflow-hidden">
                <img
                  src={image}
                  alt=""
                  className="w-72 md:w-96 h-auto hover:saturate-0 duration-300 cursor-pointer"
                />
                <div className="absolute bottom-0 right-32 md:right-8 p-5 md:p-10 text-white text-center">
                  <h2 className="text-lg md:text-xl font-semibold">{name}</h2>
                  <p className="text-xs md:text-sm">{desc}</p>
                </div>
              </div>
            ))}
          </Carousel>
        )} */}


    </motion.div>
  )
}

export default Feedback