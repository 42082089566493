import { React, useEffect, useState } from 'react'
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom'
import { Link as SmoothLink } from 'react-scroll'
import { useNavigate } from "react-router";


import footer_logo from '../assets/navbar/navbar_logo.svg'


const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const navigate = useNavigate();
  const handleScrollTo = (elementId) => {
    navigate("/"); 
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  
  const links = [
    {
      id:1,
      link:'/about_us',
      name:'About Us',
    },
    {
      id:2,
      link:'/privacy_policy',
      name:'Privacy Policy',
    },
    {
      id:3,
      link:'/terms_conditions',
      name:'Terms and Conditions',
    },
    {
      id:3,
      link:'/refund_policy',
      name:'Refund Policy',
    },
  ]

  const [data, setData] = useState(null);

  useEffect(() => {

    fetch('https://api.talentitan.com/api/v1.0/links')
      .then((response) => response.json())
      .then((responseData) => {
        
        const filteredData = { ...responseData.data };
        delete filteredData.privacy_policy;
        delete filteredData.terms_conditions;
        delete filteredData.about_us;
        delete filteredData.contact_us;

        setData(filteredData);
      })
      .catch((error) => {
        console.error('Error occured while fetching data: ', error);
      });
  }, []);

  return (
    <div className='w-full h-60 md:h-28 px-4 text-white bg-black z-10'>

      <div className='flex justify-between'>
        <div className='cursor-pointer'>
          <img src= {footer_logo} alt='TT Logo' className='w-52'></img>
        </div>
        <div className='cursor-pointer flex justify-end items-end'>
        {data &&
            Object.entries(data).map(([name, link]) => (
              (name == "facebook" || name == "instagram" || name == "app_walkthrough_video" || name == "talentitan_app_link" || name == "linkedin")?
              <SocialIcon
                key={name}
                url={link}
                fgColor="#be95c4"
                bgColor="transparent"
                target="_blank"
                rel="noreferrer"
              />
              :<></>
            ))}
        </div>
      </div>
      
      <div className='flex flex-col-reverse justify-center md:flex-row md:justify-between'>

        <p className='text-center md:block'>Made by <span className='font-bold'>TalenTitan</span>. All rights reserved.</p>

        <ul className='flex justify-center items-center py-10 md:py-0'>
            <li className='text-center text-sm md:text-md md:text-left px-4 cursor-pointer text-white  hover:text-[#97D9FF] hover:underline decoration-[#8917C7] duration-200'>
              <SmoothLink to='contact' smooth duration={500} spy className="cursor-pointer" onClick={() => handleScrollTo("contact")}>
                Contact
              </SmoothLink>
            </li>
            {links.map(({id, link, name}) => ( 
                <li key={id} className='scroll-to-top text-center text-sm md:text-md md:text-left px-4 cursor-pointer text-white  hover:text-[#97D9FF] hover:underline decoration-[#8917C7] duration-200'>
                  <Link to={link} onClick={scrollToTop}>
                    {name}
                  </Link>
                </li>
            ))}
        </ul>
      </div>
        
    </div>

  )
}

export default Footer