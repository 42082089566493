import React from 'react';
import ReactMarkdown from 'react-markdown';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const RefundPolicyPage = (props) => {
    return (
        <div>
            <Navbar />
                <div className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center">
                    <div className="p-4 pt-28">
                        <p className="text-4xl font-bold inline text-[#97D9FF] underline text-center">Refund Policy</p>
                    </div>
                    <div className="markdown-2 pt-10 px-12 md:px-40">
                        <ReactMarkdown>TalenTitan Allow users to play Quizzes, Battles, Games, and participate in various types of events. Users will receive AxPi for every action they do and users can redeem AxPi in the form of TopUps or Gift Cards. So there is no refund pollicy in TalenTitan Private Limited.</ReactMarkdown>
                    </div>
                </div>
            <Footer />
        </div>
    );
}

export default RefundPolicyPage;
