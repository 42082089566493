import { React, useState } from "react";
import { Link } from "react-scroll";
import navbar_logo from "../assets/navbar/navbar_logo.svg";
import { Navigate, useNavigate } from "react-router";
import {FaBars, FaTimes} from 'react-icons/fa';
import { motion } from "framer-motion"



const Navbar = () => {

  const [nav, setNav] = useState(false);

  const navigate = useNavigate();
  
  const links = [
    {
      id: 1,
      link: "battles",
      name: "How It Works",
    },
    {
      id: 2,
      link: "feedback",
      name: "Feedback",
    },
    {
      id: 3,
      link: "services",
      name: "Services We Provide",
    },
    {
      id:4,
      link:'contact',
      name:'Contact Us',
    },
  ];

  const handleScrollTo = (elementId) => {
    navigate("/"); 
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-black fixed z-30">
      <Link to="home" smooth duration={500} spy className="cursor-pointer" onClick={() => handleScrollTo("home")} >
        <img src={navbar_logo} alt="TT Logo" className="w-48 md:w-60"></img>
      </Link>


      <ul
        className='hidden md:flex md:space-x-4 md:items-center'
      >
        {links.map(({ id, link, name }) => (
          <li
            key={id}
            className="px-4 cursor-pointer font-medium hover:underline decoration-[#8917C7] duration-200"
          >
            <Link
              to={link}
              smooth
              duration={500}
              spy
              activeClass="active" 
              className="bg-[#97D9FF] bg-clip-text text-transparent"
              onClick={() => handleScrollTo(link)} 
            >
              {name}
            </Link>
          </li>
        ))}
          <li className="px-4 cursor-pointer font-medium">
            <a href="https://play.google.com/store/apps/details?id=com.talen_titan&pli=1" target="_blank" rel="noreferrer" className="bg-[#97D9FF] bg-clip-text text-transparent">
              <div className="border-2 border-[#97D9FF] rounded-xl p-2 inline-block">
                Download App
              </div>
            </a>
          </li>
      </ul>

      <motion.div
        initial={{
          opacity:0,
        }}
        whileInView={{
          opacity:1,
        }}
        transition={{
          duration:2,
        }}
        onClick={() => setNav(!nav)} 
        className="cursor-pointer pr-4 z-10 text-[#8917C7] md:hidden" >
            {nav ? <FaTimes size={30} /> : <FaBars size={30}/>}
        </motion.div>


        {nav && (
          
            <motion.ul 
                initial={{
                  opacity:0,
                }}
                whileInView={{
                  opacity:1,
                }}
                transition={{
                  duration:1,
                }}
              className='flex flex-col justify-center items-center absolute top-20 right-2 w-44 h-46 bg-black rounded-sm text-[#97D9FF] '>
                {links.map(({id, name, link}) => ( 
                    <li key={id} className='px-4 py-1 cursor-pointer capitalize text-sm hover:underline decoration-[#8917C7] duration-200'>
                        <Link onClick={() => setNav(!nav)} 
                        to={link} 
                        smooth 
                        duration={500}
                        spy>
                            {name}
                        </Link>                
                    </li>
                  ))}
                  <li className="px-4 cursor-pointer font-medium hover:underline decoration-[#8917C7] duration-200">
                    <a href="https://play.google.com/store/apps/details?id=com.talen_titan&pli=1" target="_blank" rel="noreferrer" className="bg-[#97D9FF] bg-clip-text text-transparent">
                      Download App
                    </a>
                  </li>
            </motion.ul>
        )}
      
      


    </div>
  );
};

export default Navbar; 

