import { React } from "react";
import ReactMarkdown from 'react-markdown'
import { motion } from 'framer-motion'
import { Link } from 'react-scroll'
import { useNavigate } from "react-router";


import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import project_1 from '../../assets/services/project-mgmt/project_1.jpg'
import project_2 from '../../assets/services/project-mgmt/project_2.jpg'
import project_3 from '../../assets/services/project-mgmt/project_3.jpg'

const ProjectMgmt = () => {

      const navigate = useNavigate();

      const handleScrollTo = (elementId) => {
            navigate("/"); 
            setTimeout(() => {
              const element = document.getElementById(elementId);
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }, 100);
          };

      const indiv_project = [
      {
            id: 1,
            name: "project_1",
            image: project_1,
      },
      {
            id: 2,
            name: "project_2",
            image: project_2,
      },
      {
            id: 3,
            name: "project_3",
            image: project_3,
      },
      
      ]
              
  const markdownContent = `

  At TalenTitan, we understand that successful project management is the cornerstone of any thriving business. Our dedicated team of project management professionals is here to streamline your projects, ensuring they are delivered on time, within scope, and on budget. Our comprehensive project management services encompass:
  
  Our end-to-end product development services encompass:
  
  1. **Project Planning and Initiation**

        - **Project Scoping:** Collaborate with us to define clear project objectives, deliverables, and success criteria.

        - **Project Charter:** Develop a project charter that outlines roles, responsibilities, and project constraints.

  2. **Resource Allocation and Team Building**

        - **Resource Planning:** We help you identify and allocate the right resources—human, financial, and technological—to maximize project efficiency.

        - **Team Assembly:** Form cross-functional teams with the skills and expertise necessary for project success.

  3. **Timeline and Milestone Management**

        - **Project Scheduling:** Create a detailed project schedule, identifying key milestones and dependencies.

        - **Timeline Monitoring:** We track project progress in real-time, ensuring adherence to deadlines.

  4. **Risk Assessment and Mitigation**

        - **Risk Identification:** Identify potential risks and challenges that could impact project delivery.

        - **Risk Mitigation:** Develop strategies to mitigate risks, ensuring project continuity.

  5. **Budgeting and Cost Control**

        - **Budget Development:** Establish a comprehensive project budget, monitoring and controlling expenditures throughout the project lifecycle.

        - **Cost Analysis:** Analyze project costs and optimize spending to maintain budget compliance.

  6. **Communication and Stakeholder Engagement**

        - **Stakeholder Management:** Implement strategies to engage stakeholders, ensuring alignment with project objectives.

        - **Effective Communication:** Facilitate transparent and efficient communication channels within project teams and with stakeholders.

  7. **Quality Assurance and Monitoring**

        - **Quality Standards:** Define and uphold quality standards for project deliverables.

        - **Quality Control:** Continuously monitor project outputs to maintain high-quality results.

  8. **Change Management**

        - **Change Control:** Implement a structured change management process to handle modifications to project scope or requirements.

        - **Adaptability:** Ensure the project team can adapt to changes effectively.

  9. **Performance Reporting and Analysis**

        - **Progress Reporting:** Regularly report project progress, including key performance indicators (KPIs) and metrics.

        - **Performance Analysis:** Utilize data analysis to identify areas for improvement and optimize project processes.

  10. **Project Closure and Evaluation**

        - **Project Closure:** Facilitate a systematic project closure process, including handovers and documentation.

        - **Post-Project Evaluation:** Conduct a comprehensive review to gather lessons learned and improve future project management practices.

  11. **Agile and Lean Methodologies**

        - **Agile Practices:** Implement agile methodologies to enhance flexibility and responsiveness in project management.

        - **Lean Principles:** Apply lean principles to eliminate waste and optimize processes.
  
  No matter the size or complexity of your project, TalenTitan is committed to delivering results. We tailor our project management services to meet your unique needs, ensuring your projects are executed efficiently and effectively.

  Ready to elevate your project management practices? Contact us today to discuss how our project management services can help you achieve your project goals.
  `;
  return (
    

    <div>
      <Navbar/>
      <div className=" w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center">
        <div className="p-4 pt-28">
          <p className="text-4xl font-bold inline text-[#97D9FF] underline">Project Management</p>
        </div>
        <motion.div
          initial={{ x: -300 }} 
          animate={{ x: 0 }}   
          transition={{ duration: 1.5, ease: "easeOut" }}
          className="flex flex-wrap items-center justify-center w-full mx-auto">
            {indiv_project.map(({ id, name, image }) => (
              <div key={id} className="p-2 mt-4">
  
                <img src={image} alt="" className="w-48 rounded-lg hover:saturate-0 hover:scale-105 duration-300 cursor-pointer" />

              </div>
        ))}

        </motion.div>
        
        <div className="markdown pt-10 px-32 md:px-40 ">
            <ReactMarkdown children = {markdownContent} sourcePos={false}/>
            <br/>
            <p className="text-2xl"><Link to="contact" smooth duration={500} spy className="cursor-pointer underline" onClick={() => handleScrollTo("contact")}>Contact</Link> us now!</p>
            <br/><br/><br/>
            </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ProjectMgmt