import { React } from "react";
import ReactMarkdown from 'react-markdown'
import { motion } from 'framer-motion'
import { Link } from 'react-scroll'
import { useNavigate } from "react-router";

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import iot_1 from '../../assets/services/iot-project/iot_1.jpg'
import iot_2 from '../../assets/services/iot-project/iot_2.jpg'
import iot_3 from '../../assets/services/iot-project/iot_3.jpg'

const IoTProject = () => {

  const navigate = useNavigate();

  const handleScrollTo = (elementId) => {
        navigate("/"); 
        setTimeout(() => {
          const element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      };

  const indiv_iot = [
    {
      id: 1,
      name: "iot_1",
      image: iot_1,
    },
    {
      id: 2,
      name: "iot_2",
      image: iot_2,
    },
    {
      id: 3,
      name: "iot_3",
      image: iot_3,
    },
    
  ]

  const markdownContent = `
  Transform your ideas into smart, connected solutions with our expert IoT project creation services. At TalenTitan, we specialize in designing, developing, and deploying innovative IoT projects that leverage cutting-edge technologies to drive efficiency, enhance user experiences, and unlock new possibilities for your business. 
  
  Our comprehensive IoT project creation services encompass:
  
  1. **Ideation and Consultation**

      - **IoT Strategy Development:** We work closely with you to understand your business goals and help you conceptualize IoT solutions that align with your objectives.

      - **Requirements Analysis:** Our team conducts a thorough analysis of your project requirements, ensuring every aspect is considered for a successful IoT implementation.

  2. **Hardware Development**

      - Custom IoT Device Design: We create tailored IoT hardware solutions to meet the unique demands of your project, optimizing for performance, reliability, and cost-effectiveness.

      - Sensor Integration: Harness the power of data with a wide range of sensors, from environmental to biometric, seamlessly integrated into your IoT devices.

  3. **Software Development**

      - **IoT Application Development:** Our skilled software engineers craft robust, user-friendly IoT applications tailored to your specific project needs.

      - **Cloud Integration:** Ensure seamless data storage, processing, and real-time access with secure cloud integrations for your IoT ecosystem.

      - **Firmware Development:** We develop and maintain the firmware that drives your IoT devices, ensuring they stay up-to-date and secure.

  4. **Connectivity Solutions**

      - **IoT Protocols:** Utilize the most suitable communication protocols such as MQTT, CoAP, or LoRa to enable efficient device-to-device and device-to-cloud communication.

      - Wireless and Cellular Connectivity: We implement a wide range of connectivity options, including Wi-Fi, Bluetooth, cellular, and LPWAN, ensuring your IoT devices are always connected.

  5. **Data Analytics and Insights**
      - **Data Collection and Analysis:** Leverage the data generated by your IoT devices to gain valuable insights, make informed decisions, and optimize your operations.

      - **Predictive Maintenance:** Implement predictive analytics to detect potential issues before they impact your business, reducing downtime and maintenance costs.

  6. **Security and Compliance**

      - **End-to-End Security:** We prioritize the security of your IoT ecosystem, implementing encryption, authentication, and access control measures to safeguard your data.

      - **Regulatory Compliance:** Ensure that your IoT project adheres to industry-specific regulations and standards, mitigating legal and compliance risks.

  7. Deployment and Support

      - Project Deployment: We handle the seamless deployment of your IoT solution, ensuring it functions optimally in real-world conditions.

      - Ongoing Maintenance: Our support team provides continuous monitoring, updates, and troubleshooting to keep your IoT project running smoothly.
      
  8. **Scalability and Future-Proofing**

      - **Scalability:** Design your IoT solution to easily scale as your business grows, accommodating additional devices and functionalities.

      - **Technology Roadmap:** Create a roadmap for your IoT project's future enhancements and integrations to stay competitive in a rapidly evolving landscape.
  
  Unlock the full potential of the Internet of Things with TalenTitan. Whether you're looking to optimize operations, enhance user experiences, or explore new revenue streams, our IoT project creation services are tailored to meet your unique requirements.

  Ready to embark on your IoT journey? Contact us today to discuss how we can turn your IoT vision into a reality.
  `;

  return (
    <div>
    <Navbar/>
    <div className=" w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center">
      <div className="p-4 pt-28">
        <p className="text-4xl font-bold inline text-[#97D9FF] underline">IoT Project Creation</p>
      </div>
      <motion.div
          initial={{ x: -300 }} 
          animate={{ x: 0 }}   
          transition={{ duration: 1.5, ease: "easeOut" }}
          className="flex flex-wrap items-center justify-center w-full mx-auto">
            {indiv_iot.map(({ id, name, image }) => (
              <div key={id} className="p-2 mt-4">
  
                <img src={image} alt="" className="w-48 rounded-lg hover:saturate-0 hover:scale-105 duration-300 cursor-pointer" />

              </div>
        ))}

        </motion.div>
        <div className="markdown pt-10 px-32 md:px-40 ">
            <ReactMarkdown children = {markdownContent} sourcePos={false}/>
            <br/>
            <p className="text-2xl"><Link to="contact" smooth duration={500} spy className="cursor-pointer underline" onClick={() => handleScrollTo("contact")}>Contact</Link> us now!</p>
            <br/><br/><br/>
            </div>
      </div>
    <Footer/>
  </div>
  )
}

export default IoTProject