import React, { useRef, useEffect, useState } from 'react';
import { motion } from "framer-motion"

import battles_bg from "../assets/battles/battles_bg.svg";
import battle_1_dp from "../assets/battles/battle_1_dp.svg";
import battle_2_painting from "../assets/battles/battle_2_painting.svg";
import battle_3_tattoo from "../assets/battles/battle_3_tattoo.svg";
import battle_4_pet from "../assets/battles/battle_4_pet.svg";
import battle_5_flower from "../assets/battles/battle_5_flower.svg";
import battle_6_food from "../assets/battles/battle_6_food.svg";

const Battles = () => {

  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  const maxWords = 50; 
  const [showModal, setShowModal] = useState(false); 
  const [selectedBattle, setSelectedBattle] = useState(null); 

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once it's in view
        }
      });
    });

    observer.observe(componentRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const indiv_battles = [
    {
      id: 1,
      name: "DP Battle",
      image: battle_1_dp,
      desc: "In terms of social media be it Instagram, facebook, or Snapchat DP is a ubiquitous word which is also known as display picture and has become an essential part of our lives. So, this battle is all about displaying pictures where your image will be opposite to some other person's display picture, whosoever will have the highest vote will be the winner of that match. Get more votes and be the talent of your titan.",
    },
    {
      id: 2,
      name: "Painting Battle",
      image: battle_2_painting,
      desc: "Colors are the beautiful gift of nature and the skill or action of using these colors or paint is painting. This battle is all about the action or skill of using paint. So, here one painting will be in front of his competitor's painting and the one who will have more votes will be the winner of that particular match. Get more votes and be the talent of your titan.",
    },
    {
      id: 3,
      name: "Tattoo Battle",
      image: battle_3_tattoo,
      desc: "Mehndi is a type of body art that is drawn on the hands or legs of a human body in order to adorn the body temporarily with decorative designs using a paste created from the powdered dry leaves of the henna plant. So, this battle is all about making a temporary tattoo and here one's tattoo will be placed in front of his competitor. Whosoever will get the highest votes on his mehndi will win that match. Get more votes and be the talent of your titan.",
    },
    {
      id: 4,
      name: "Pet Battle",
      image: battle_4_pet,
      desc: "Most people love animals and treat their pets like valued members of the family. Pets provide companionship, bring joy to our lives, give us affection, and we share our homes, our food, and our lives with them and do everything we can to ensure their well-being and happiness. So, here is a Pet Battle where you can upload your pet picture and get more votes for your pet to become a winner of a Battle.",
    },
    {
      id: 5,
      name: "Flower Battle",
      image: battle_5_flower,
      desc: "Flowers have the ability to make people less lonely and sad. This positive effect on people's moods is a game changer – and one many of us have tapped into by giving flowers to communicate various emotions. So, here comes a Flower Battle where you can upload flowers and beautiful pictures and win this battle by getting a maximum number of votes.",
    },
    {
      id: 6,
      name: "Food Battle",
      image: battle_6_food,
      desc: "Whether you're a professional photographer or just someone who enjoys taking pictures of their meals, food photography has something to offer everyone. In conclusion, food photography is an important art form that plays a vital role in promoting healthy eating, sustainability, and the joy of food. So, here comes a Food Battle where you can upload food pictures and get maximum votes to win this battle.",
    },
  ];

  return (
    <motion.div
      initial={{
          opacity:0,
      }}
      whileInView={{ 
          opacity:1,
      }}
      transition={{
          duration:0.5,
      }}
      id="battles"
      className="w-full bg-gradient-to-b from-black via-[#000C36] to-black bg-cover bg-center relative text-white flex flex-col justify-start items-center pb-8"
    >
      <div
        className="h-full w-full absolute bg-cover bg-center"
        style={{
          backgroundImage: `url(${battles_bg})`,
          backgroundSize: "cover",
        }}
      />

      <div className="p-4 pt-28 text-center">
        <p className="text-xl md:text-4xl font-bold inline text-[#97D9FF]">Battles</p>
        <p className="text-sm md:text-md pt-5 px-10 text-[#97D9FF]">
          Click on the descriptions to know more!
        </p>
      </div>
      
      
      <motion.div
        ref={componentRef}
        initial={{ x: -300 }} 
        animate={{ x: 0 }}   
        transition={{ duration: 1, ease: "easeOut" }}
        className="flex flex-wrap items-center justify-center w-full mx-auto">
           {indiv_battles.map(({ id, name, image, desc }) => (
          <div key={id} className="p-2 ">
            <div className="relative">
              <img src={image} alt="" className="w-64 md:w-96 rounded-lg hover:saturate-0 hover:scale-105 duration-300 cursor-pointer" />
              <div className="absolute bottom-0 left-0 p-2 text-white text-center pb-2">
                <h2 className="text-xl font-semibold">{name}</h2>
                {desc.split(' ').length > maxWords ? (
                  <p className="text-xs cursor-pointer" onClick={() => { toggleModal(); setSelectedBattle({ id, name, desc }); }}>
                    {showModal && selectedBattle && selectedBattle.id === id ? selectedBattle.desc : `${desc.split(' ').slice(0, maxWords).join(' ')}...`}
                  </p>
                ) : (
                  <p className="text-xs">{desc}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </motion.div>

      {/* Modal */}
      {showModal && selectedBattle && (
        <div className=" fixed inset-0 md:inset-40 flex items-center justify-center z-50">
          <div className="text-[#97D9FF] bg-gray-700 p-4 rounded-lg shadow-md">
            <p className="text-xl font-semibold">{selectedBattle.name}</p>
            <p>{selectedBattle.desc}</p>
            <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600" onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Battles;
